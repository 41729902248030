<template>
  <ul>
    <li
        v-for="(item, k) in tabsList" :key="k"
    >
        <router-link :to="item.url">{{ item.tit }}</router-link>
    </li>
</ul>
</template>

<script>
export default {
    data() {
        return {
            tabsList: [
                {
                id: 0,
                url: "/about/intro",
                tit: "公司简介",
                },
                {
                id: 1,
                url: "/about/honor",
                tit: "荣誉资质",
                },
                {
                id: 2,
                url: "/about/client",
                tit: "典型客户",
                },
                {
                id: 3,
                url: "/about/case",
                tit: "典型案例",
                },
                {
                id: 4,
                url: "/about/join",
                tit: "加入我们 ",
                },
                {
                id: 5,
                url: "/about/cjrh",
                tit: "产教融合 ",
                },
            ],
        }
    },
    methods: {
        tabsCli(id) {
            this.activeClass = id.id;
        },
    },
}
</script>

<style>

</style>