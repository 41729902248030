<template>
  <div class="main">
    <div class="about">
      <div class="pageBanner">
        <div class="banner">
          <div class="describe">
            <div class="tit">关于我们<span>/ABOUT US</span></div>
            <div class="txt">
              为客户提供专业的数据处理、技术开发、数据解决方案，是一家集研发、生产、销售于一体的<br />大数据企业。
            </div>
          </div>
        </div>
      </div>
      <div class="aboutCont">
        <div class="tabs">
          <about-side-nav />
        </div>
        <div class="tabsDtail">
          <router-view />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AboutSideNav from '@/components/AboutSideNav.vue';
export default {
  components: { AboutSideNav },
  name: "about",
};
</script>
<style lang="scss">
.el-carousel__container {
  height: 290px;
}
button.el-carousel__arrow.el-carousel__arrow--left,
button.el-carousel__arrow.el-carousel__arrow--right {
  font-size: 12px;
  height: 40px;
  width: 40px;
  background: rgba(255, 255, 255, 0.849);
}
@import "../scss/about.scss";
</style>